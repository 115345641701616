export const FirebaseOptions = {
  apiKey: "AIzaSyADkydalASOp6JEaMlZ_-5rNxTpYmPLhgM",
  authDomain: "dev-lesjours.firebaseapp.com",
  projectId: "dev-lesjours",
  storageBucket: "dev-lesjours.appspot.com",
  messagingSenderId: "77024713692",
  appId: "1:77024713692:web:c6099c18772efc34f40d19",
  measurementId: "G-FE91BHSKTP",
};

export const WebPushCertificates = {
  vapidKey: "BBJF2yT0v9DXJrlbvfrkm65MCE5SzATISM2iLtnUMEDtoNpg_c05uarayqnIbs1aS8U41npLFsmohF0l8jJdG2U",
};

export const SignInWithOptions = {
  appleClientId: "fr.lesjours.website",
  googleClientId: "77024713692-ck6nuuguf6k2h0n3oac027sgmc0kagio.apps.googleusercontent.com",
  redirectURI: "https://dev.lesjours.fr/mon-compte/register",
};

export const SubscribeWithGoogleOptions = {
  productId: "dev.lesjours.fr:jouriste",
  ljOAuthClientId: "google-swg",
};
